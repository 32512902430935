import DocxToHtmlDriver from './drivers/docxToHtml'
import PdfToHtmlDriver from './drivers/pdfToHtml'
import HtmlToDocx from './drivers/htmlToDocx'

const TARGET_EXTENSION_HTML = 'html',
  TARGET_EXTENSION_DOCX = 'docx'

export const availableTargetExtensions = {
  TARGET_EXTENSION_HTML,
  TARGET_EXTENSION_DOCX,
}

export default class Converter {
  async convertFile(file, options = {}) {
    return await this.resolveDriver(
      {
        file,
        target: availableTargetExtensions.TARGET_EXTENSION_HTML,
      },
      options,
    ).convertFile(file)
  }

  async convertContent(html, options = {}, htmlToDocxOptions = {}) {
    return await this.resolveDriver({ target: 'docx' }, options).convertHtml({
      html,
      options: htmlToDocxOptions,
    })
  }

  resolveDriver({ file = null, target = 'html' }, options) {
    if (target === 'html') {
      const extension = file.name.split('.').pop()

      switch (extension) {
        case 'docx':
          return new DocxToHtmlDriver(options)
        case 'pdf':
          return new PdfToHtmlDriver(options)
      }
    }

    if (target === 'docx') {
      return new HtmlToDocx(options)
    }
  }
}
