import ApiService from '@/services/ApiService'
import { getVerUri } from '@/services/ApiService'

const axios = ApiService._axios

export default class PdfConverter {
  constructor() {
    this.html = null
    this.errorMessages = []
  }

  async convertFile(file){
    let formData = new FormData
    formData.append('file', file)

    let res = await axios.post(getVerUri(`converter/pdf/text`), formData)
    if (res.data.success){
      return { html: res.data.content, errors: [] }
    } else {
      return { html: '', errors: ['Convert failed'] }
    }
   }

}
