import HTMLtoDOCX from 'html-to-docx'

const defaultOptions = {
  title: 'Contract Connected document',
  creator: 'Contracts Connected',
  skipFirstHeaderFooter: true,
  margins: {
    top: 800,
    right: 1200,
    bottom: 1440,
    left: 1200,
    header: 720,
    footer: 720,
    gutter: 0,
  },
}

export default class HtmlToDocx {
  constructor() {
    this.html = null
    this.errorMessages = []
  }

  async convertHtml({ html, options }){
    let htmlString = `<!DOCTYPE html>
      <html lang="en">
          <head>
              <meta charset="UTF-8" />
              <title>Document</title>
          </head>
          <body style="margin-bottom: 200px">
          ${html}
          </bodyst>
      </html>`
    let finalOptions = { ...defaultOptions, ...options }
    const blob = await HTMLtoDOCX( htmlString, null, finalOptions)

    return { blob, errors: this.errorMessages}
  }
}
