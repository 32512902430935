import mammoth from 'mammoth'
import mapLodash from 'lodash/map'

function transformElement(element) {
  if (element.children) {
    let children = mapLodash(element.children, transformElement)
    element = { ...element, children: children }
  }

  if (element.styleId?.includes('Heading')) {
    element = transformHeading(element)
  } else if (element.type === 'paragraph') {
    element = transformParagraph(element)
  }
  return element
}

function transformHeading(element) {
  if (element.alignment === 'center') {
    return { ...element, styleName: `textCenter${element.styleId || ''}` }
  } else if (element.alignment === 'right') {
    return { ...element, styleName: `textRight${element.styleId || ''}` }
  } else if (element.alignment === 'both') {
    return { ...element, styleName: `textJustify${element.styleId || ''}` }
  } else {
    return element
  }
}

function transformParagraph(element) {
  if (element.alignment === 'center' && !element.styleName) {
    return { ...element, styleName: 'textCenter' }
  } else if (element.alignment === 'right' && !element.styleName) {
    return { ...element, styleName: 'textRight' }
  } else if (element.alignment === 'both' && !element.styleName) {
    return { ...element, styleName: 'textJustify' }
  } else {
    return element
  }
}

export default class DocxConverter {
  constructor() {
    this.html = null
    this.errorMessages = []
  }

  async convertFile(file) {
    let res = await this.readFileInputEventAsArrayBuffer(file)
    await this.convertToHtml(res)
    return { html: this.html, errors: this.errorMessages }
  }

  async readFileInputEventAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()

      reader.onload = () => {
        resolve(reader.result)
      }

      reader.onerror = reject

      reader.readAsArrayBuffer(file)
    })
  }

  async convertToHtml(arrayBuffer) {
    let res = await mammoth.convertToHtml(
      { arrayBuffer: arrayBuffer },
      {
        transformDocument: transformElement,
        styleMap: [
          "p[style-name='textCenter'] => p.text-center:fresh",
          "p[style-name='textRight'] => p.text-right:fresh",
          "p[style-name='textJustify'] => p.text-justify:fresh",

          "p[style-name='textCenterHeading1'] => h1.text-center:fresh",
          "p[style-name='textRightHeading1'] => h1.text-right:fresh",
          "p[style-name='textJustifyHeading1'] => h1.text-justify:fresh",

          "p[style-name='textCenterHeading2'] => h2.text-center:fresh",
          "p[style-name='textRightHeading2'] => h2.text-right:fresh",
          "p[style-name='textJustifyHeading2'] => h2.text-justify:fresh",

          "p[style-name='textCenterHeading3'] => h3.text-center:fresh",
          "p[style-name='textRightHeading3'] => h3.text-right:fresh",
          "p[style-name='textJustifyHeading3'] => h3.text-justify:fresh",
        ],
      },
    )
    this.html = res.value
    this.errorMessages = res.messages
  }
}
